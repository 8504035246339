import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ExternalInvoicesStateModel } from './external-invoices.state-model';
import { ExternalInvoicesService } from '../../services/external-invoices.service';

import { LoadExternalInvoices, OpenExternalInvoicesList, ProcessExternalInvoices } from './external-invoices.actions';
import { EnvironmentState } from '../environment';
import { WorkOrder } from '../../models';

@State<ExternalInvoicesStateModel>({
  name: 'externalInvoices',
  defaults: {
    workOrders: [],
    workOrder: null,
  },
})
@Injectable()
export class ExternalInvoicesState {
  public constructor(private store: Store, private externalInvoicesService: ExternalInvoicesService) {}

  @Selector()
  static workOrders(state: ExternalInvoicesStateModel): Array<WorkOrder> {
    return state.workOrders;
  }

  @Selector()
  static workOrder(state: ExternalInvoicesStateModel): WorkOrder {
    return state.workOrder;
  }

  @Action(OpenExternalInvoicesList)
  public openExternalInvoicesList(_: StateContext<ExternalInvoicesStateModel>): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return this.store.dispatch(new Navigate([`/${prefix}/external-invoices`]));
  }

  @Action(LoadExternalInvoices)
  public loadExternalInvoices(
    ctx: StateContext<ExternalInvoicesStateModel>,
    { filters }: LoadExternalInvoices
  ): Observable<Array<WorkOrder>> {
    return this.externalInvoicesService
      .getExternalInvoices(filters)
      .pipe(tap((workOrders: Array<WorkOrder>) => ctx.patchState({ workOrders })));
  }

  @Action(ProcessExternalInvoices)
  public processExternalInvoices(
    ctx: StateContext<ExternalInvoicesStateModel>,
    { processedExternalInvoices }: ProcessExternalInvoices
  ): Observable<Array<WorkOrder>> {
    return this.externalInvoicesService
      .processExternalInvoices(processedExternalInvoices)
      .pipe(tap((workOrders: Array<WorkOrder>) => ctx.patchState({ workOrders })));
  }
}
