import { Expose, Exclude, Transform } from 'class-transformer';

import { Product } from './product';

import { defaultValueTransformer } from '../transformers';

@Exclude()
export class RefillableProduct extends Product {
  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public quantity: number;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public refilled: number;
}
