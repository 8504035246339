import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { ModulesStateModel } from './modules.state-model';

import { SetModules, SetActiveModule } from './modules.actions';

@State<ModulesStateModel>({
  name: 'modules',
  defaults: {
    modules: [],
    activeModule: null,
  },
})
@Injectable()
export class ModulesState {
  @Selector()
  static modules(state: ModulesStateModel): Array<any> {
    return state.modules;
  }

  @Selector()
  static activeModule(state: ModulesStateModel): any {
    return state.activeModule;
  }

  @Action(SetModules)
  public setModules(ctx: StateContext<ModulesStateModel>, { modules }: SetModules): ModulesStateModel {
    return ctx.patchState({ modules });
  }

  @Action(SetActiveModule)
  public setActiveModules(ctx: StateContext<ModulesStateModel>, { activeModule }: SetActiveModule): ModulesStateModel {
    return ctx.patchState({ activeModule });
  }
}
