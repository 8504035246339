import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';

import { classToPlain, plainToClass } from 'class-transformer';

import { ObjectGenerator } from '../../utils/object-generator';
import { ReportsStateModel } from '@vandelft/modules/shared/state/reports/reports.state-model';
import { ReportsService } from '@vandelft/modules/shared/services/reports.service';

import {
  AddNoteToReport,
  ApproveReport,
  CancelReport,
  CreateReport,
  DeleteReport,
  LoadReport,
  LoadReports,
  LoadReportsByMechanic,
  LoadReportsByMechanics,
  OpenReportApproval,
  OpenReportDetails,
  OpenReportForm,
  OpenReportsList,
  SaveReport,
  SaveReportTimes,
  SetReport,
  LoadReportsWithOrderProducts,
} from './reports.actions';
import { Image, LocalImage, Note, Report } from '../../models';
import { EnvironmentState } from '../environment';
import { switchMap, tap } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { AuthState } from '../auth';
import { AppEventsService } from '../../services/app-events.service';
import { insertItem, patch, removeItem } from '@ngxs/store/operators';
import { v4 } from 'uuid';

@State<ReportsStateModel>({
  name: 'reports',
  defaults: {
    reports: [],
    report: null,
  },
})
@Injectable()
export class ReportsState {
  public constructor(
    private store: Store,
    private reportsService: ReportsService,
    private appEventsService: AppEventsService,
  ) {}

  @Selector()
  static reports(state: ReportsStateModel): Array<Report> {
    return state.reports;
  }

  @Selector()
  static report(state: ReportsStateModel): Report {
    return state.report;
  }

  @Action(LoadReports)
  public async loadReports(ctx: StateContext<ReportsStateModel>): Promise<Array<Report>> {
    const reports = await this.reportsService.getReports().toPromise();

    ctx.patchState({ reports });
    return reports;
  }

  @Action(SetReport)
  public setReport(ctx: StateContext<ReportsStateModel>, { report }: SetReport): void {
    ctx.patchState({ report });
  }

  @Action(LoadReport)
  public loadReport(ctx: StateContext<ReportsStateModel>, { id }: LoadReport): Observable<Report> {
    return this.reportsService.getReportById(id).pipe(tap((report: Report) => ctx.patchState({ report })));
  }

  @Action(LoadReportsByMechanic)
  public loadReportByMechanic(
    ctx: StateContext<ReportsStateModel>,
    { mechanicId }: LoadReportsByMechanic,
  ): Observable<Report[]> {
    return this.reportsService
      .getReportsByMechanic(mechanicId)
      .pipe(tap((reports: Report[]) => ctx.patchState({ reports })));
  }

  @Action(LoadReportsWithOrderProducts)
  public loadReportsWithOrderProducts(
    ctx: StateContext<ReportsStateModel>,
    { date }: LoadReportsWithOrderProducts,
  ): Observable<any> {
    return this.reportsService.getReportsWithOrderProducts(date).pipe(
      tap((result) => {
        ctx.patchState({ reports: result });
      }),
    );
  }

  @Action(LoadReportsByMechanics)
  public async loadReportByMechanics(
    ctx: StateContext<ReportsStateModel>,
    { ids }: LoadReportsByMechanics,
  ): Promise<Report[]> {
    const res: Report[] = [];

    for (const id of ids) {
      res.push(...(await this.reportsService.getReportsByMechanic(id).toPromise()));
    }

    ctx.patchState({ reports: res });

    return res;
  }

  @Action(CreateReport)
  public createReport(ctx: StateContext<ReportsStateModel>): Observable<Report> {
    const user = this.store.selectSnapshot(AuthState.user);
    const generator = new ObjectGenerator();
    const report = generator.generateNewReport({ user });
    ctx.patchState({ report });
    return of(report);
  }

  @Action(CancelReport)
  public async cancelReport(ctx: StateContext<ReportsStateModel>, { id, reason }: CancelReport): Promise<void> {
    const report = await this.reportsService.cancelReport(id, reason).toPromise();
    ctx.patchState({ report });
  }

  @Action(OpenReportsList)
  public openReportsList(ctx: StateContext<ReportsStateModel>): void {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    ctx.dispatch(new Navigate([`/${prefix}/reports`]));
  }

  @Action(OpenReportForm)
  public openReportForm(ctx: StateContext<ReportsStateModel>, { report }: OpenReportForm): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (report) {
      ctx.patchState({ report });
      return this.store.dispatch(new Navigate([`/${prefix}/reports/${report.id}/edit`]));
    }

    ctx.patchState({ report: null });
    ctx.dispatch(new Navigate([`/${prefix}/reports/add`]));
  }

  @Action(OpenReportDetails)
  public openReportDetails(ctx: StateContext<ReportsStateModel>, { report }: OpenReportDetails): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    ctx.patchState({ report });
    return ctx.dispatch(new Navigate([`/${prefix}/reports/${report.id}`]));
  }

  @Action(OpenReportApproval)
  public openReportApproval(ctx: StateContext<ReportsStateModel>, { report }: OpenReportApproval): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return ctx.dispatch(new Navigate([`/${prefix}/reports/${report.id}/approve`]));
  }

  @Action(ApproveReport)
  public approveReport(ctx: StateContext<ReportsStateModel>, { report }: ApproveReport): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    const event = {
      event: 'report.approved',
      payload: report,
    };

    return this.appEventsService
      .save(event)
      .pipe(switchMap((_: any) => ctx.dispatch(new Navigate([`/${prefix}/reports`]))));
  }

  @Action(DeleteReport)
  public deleteReport(ctx: StateContext<ReportsStateModel>, { report }: DeleteReport): Observable<any> {
    return this.appEventsService
      .save({
        event: 'report.deleted',
        payload: report,
      })
      .pipe(
        tap((appEvent: any) =>
          ctx.setState(
            patch({
              reports: removeItem<Report>((r) => r.id === appEvent.payload.id),
            }),
          ),
        ),
      );
  }

  @Action(SaveReport)
  public async saveReport(ctx: StateContext<ReportsStateModel>, { report }: SaveReport): Promise<any> {
    const data = classToPlain(report);

    for (const image of report.images ?? []) {
      const imageData = classToPlain(image);
      if (image instanceof LocalImage) {
        await this.appEventsService
          .save({
            event: 'reportInternalImage.saved',
            payload: {
              reportId: report.id,
              ...imageData,
            },
          })
          .toPromise();
      }

      if (image instanceof Image && !!image.deleted) {
        await this.appEventsService
          .save({
            event: 'reportInternalImage.deleted',
            payload: {
              ...imageData,
            },
          })
          .toPromise();
      }
    }

    delete data.images;

    const res = await this.appEventsService
      .save({
        event: 'report.saved',
        shouldWait: true,
        payload: data,
      })
      .toPromise();

    ctx.patchState({ report });

    return res;
  }

  @Action(AddNoteToReport)
  public addNoteToReport(ctx: StateContext<ReportsStateModel>, { report, note }: AddNoteToReport): Observable<any> {
    const user = this.store.selectSnapshot(AuthState.user);
    return this.appEventsService
      .save({
        event: 'note.saved',
        payload: { ...note, id: v4(), reportId: report.id },
      })
      .pipe(
        tap((appEvent: any) => {
          ctx.setState(
            patch({
              report: patch({
                notes: insertItem(
                  plainToClass(Note, {
                    ...appEvent.payload,
                    created: new Date(),
                    user,
                  }),
                ),
              }),
            }),
          );
        }),
      );
  }

  @Action(SaveReportTimes)
  public saveReportTimes(_: StateContext<ReportsStateModel>, { report, data }: SaveReportTimes): Observable<any> {
    return this.appEventsService.save({
      event: 'reportTimes.saved',
      payload: { ...data, reportId: report.id },
      shouldWait: true,
    });
  }
}
