import { Type, Expose, Exclude, Transform } from 'class-transformer';

import { Client } from './client';
import { User } from './user';
import { WorkOrder } from './work-order';
import { Company } from './company';

import { datetimeToPlain } from '../transformers';
import { IReport } from '@vandelft/shared/interfaces';
import { Note } from './note';
import { Image } from '@vandelft/modules/shared/models/image';
import { BaseFile } from './base-file';
import { LocalImage } from './local-image';

@Exclude()
export class Report implements IReport {
  @Expose()
  public id: string;

  @Expose()
  public clientId: string;

  @Expose()
  public userId: string;

  @Expose()
  public companyId: string;

  @Expose()
  public mechanicId: string;

  @Expose()
  public approvedById: string;

  @Expose()
  public description: string;

  @Expose()
  public reference: string;

  @Expose()
  public installOrderedProducts: boolean;

  @Expose()
  public installRemarks: string;

  @Expose()
  @Type(() => Client)
  public client: Client;

  @Expose()
  @Type(() => Company)
  public company: Company;

  @Expose()
  @Type(() => User)
  public mechanic: User;

  @Expose()
  @Type(() => User)
  public user: User;

  @Expose()
  @Type(() => User)
  public approvedBy: User;

  @Expose()
  public status: string;

  @Expose()
  @Transform(datetimeToPlain, { toPlainOnly: true })
  public plannedStart: Date;

  @Expose()
  @Transform(datetimeToPlain, { toPlainOnly: true })
  public plannedEnd: Date;

  @Expose()
  public onTheGo: string;

  @Expose()
  public arrival: string;

  @Expose()
  public departure: string;

  @Expose()
  @Type(() => WorkOrder)
  public workOrders: WorkOrder[];

  @Expose()
  @Transform(datetimeToPlain, { toPlainOnly: true })
  public created: Date;

  @Expose()
  @Type(() => Note)
  public notes: Note[];

  @Expose()
  public remarks: string;

  @Expose()
  @Type(() => Report)
  public related: IReport[];

  @Expose()
  @Type(() => BaseFile, {
    discriminator: {
      property: 'internalType',
      subTypes: [
        { value: Image, name: 'image' },
        { value: LocalImage, name: 'localImage' },
      ],
    },
  })
  public images: Image[] | LocalImage[];

  public get lastWorkOrder(): WorkOrder {
    if (!this.workOrders || this.workOrders.length === 0) {
      return null;
    }

    return this.workOrders[this.workOrders.length - 1];
  }

  public get needsTimes(): boolean {
    return !!this.companyId && this.status === 'completed' && !this.hasTimes;
  }

  public get hasTimes(): boolean {
    return !!this.onTheGo && !!this.arrival && !!this.departure;
  }
}
