<div class="container">
  <div class="row">
    <div class="col col-50 mechanic-deposit-item">
      <ng-container *ngIf="(user$ | async)?.role === 'mechanic'">
        € {{ (mechanicDepositItem$ | async)?.totalAmount }}
        <small class="muted">
          Laatst afgeletterd: {{ (mechanicDepositItem$ | async)?.mechanic.lastDeposit | date: 'dd-MM-yyyy HH:mm' }}
        </small>
      </ng-container>
    </div>
    <div class="col col-50">
      <ng-container *ngIf="modules$ | async as modules">
        <ng-container *ngIf="activeModule$ | async as activeModule">
          <ul (click)="toggle()" id="mainNav" [ngClass]="{ open: isOpen }">
            <li class="current-item" *ngIf="!isOpen && activeModule">
              <a>
                <img class="icon" [src]="activeModule?.icon" />
                <span class="label">{{ activeModule?.label }}</span>
                <img class="arrow" *ngIf="modules.length > 1" src="assets/img/arrow-down.svg" />
              </a>
            </li>

            <li *ngFor="let module of modules" [ngClass]="{ active: module.label === activeModule.label }">
              <a (click)="open(module)" [id]="module.id">
                <img class="icon" [src]="module.icon" />
                <span class="label">{{ module.label }}</span>
              </a>
            </li>
          </ul>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
