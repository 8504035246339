import { Expose, Exclude, Transform } from 'class-transformer';

import { defaultValueTransformer } from '../transformers';
import { IAddress } from '@vandelft/shared/interfaces';

@Exclude()
export class Address implements IAddress {
  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public id: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public contact: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public street: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public houseNumber: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public houseNumberSuffix: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public postalCode: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public city: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public email: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public phoneNumber: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public phoneNumber2: string;

  public get streetAndHousenumber(): string {
    return `${this.street} ${this.houseNumber}${this.houseNumberSuffix || ''}`.trim();
  }

  public get fullAddress(): string {
    return `${this.streetAndHousenumber}+${this.postalCode}+${this.city}`;
  }
}
