import { Injectable } from '@angular/core';
import * as localforage from 'localforage';

import { WorkOrder, Report } from '../models';
import { plainToClass, classToPlain, classToClass } from 'class-transformer';

@Injectable({
  providedIn: 'root',
})
export class LocalStoreService {
  private reportDrafts: LocalForage;
  private workOrderDrafts: LocalForage;
  private workOrders: LocalForage;

  public constructor() {
    const defaultSettings = {
      driver: localforage.INDEXEDDB,
      version: 1.0,
    };

    this.reportDrafts = localforage.createInstance({
      ...defaultSettings,
      name: 'reportDraft',
    });
    this.workOrderDrafts = localforage.createInstance({
      ...defaultSettings,
      name: 'workOrderDrafts',
    });
    this.workOrders = localforage.createInstance({
      ...defaultSettings,
      name: 'workOrders',
    });
  }

  public async clearStorage(): Promise<void> {
    await this.workOrders.clear();
    await this.reportDrafts.clear();
    await this.workOrderDrafts.clear();
  }

  public async saveWorkOrderRaw(data: any): Promise<void> {
    await this.workOrders.setItem(data.id, data);
  }

  public async getWorkOrderDraftsRaw(): Promise<Array<string>> {
    const workOrderDraftIds = await this.workOrderDrafts.keys();
    const workOrderDrafts = [];

    for (const workOrderDraftId of workOrderDraftIds) {
      const workOrderDraft = await this.workOrderDrafts.getItem(workOrderDraftId);
      workOrderDrafts.push(workOrderDraft);
    }

    return workOrderDrafts;
  }

  public async getWorkOrdersRaw(): Promise<Array<string>> {
    const workOrderIds = await this.workOrders.keys();
    const workOrders = [];

    for (const workOrderId of workOrderIds) {
      const workOrder = await this.workOrders.getItem(workOrderId);
      workOrders.push(workOrder);
    }

    return workOrders;
  }

  public async getWorkOrders(): Promise<Array<WorkOrder>> {
    const workOrderIds = await this.workOrders.keys();
    const workOrders = [];

    for (const workOrderId of workOrderIds) {
      const workOrder = await this.getWorkOrder(workOrderId);
      workOrders.push(workOrder);
    }

    return workOrders;
  }

  public async saveWorkOrder(workOrder: WorkOrder): Promise<WorkOrder> {
    const workOrderData = classToPlain(classToClass(workOrder)) as WorkOrder;
    await this.workOrders.setItem(workOrder.id, workOrderData);
    return workOrderData;
  }

  public async getWorkOrder(workOrderId: string): Promise<WorkOrder> {
    const data = await this.workOrders.getItem(workOrderId);
    return plainToClass(WorkOrder, data);
  }

  public async deleteWorkOrder(workOrder: WorkOrder): Promise<void> {
    return await this.workOrders.removeItem(workOrder.id);
  }

  public async saveWorkOrderDraft(workOrder: WorkOrder): Promise<WorkOrder> {
    const workOrderData = classToPlain(classToClass(workOrder)) as WorkOrder;
    await this.workOrderDrafts.setItem(workOrder.id, workOrderData);
    return workOrderData;
  }

  public async getWorkOrderDraft(workOrderId: string): Promise<WorkOrder> {
    const data = (await this.workOrderDrafts.getItem(workOrderId)) as any;
    const report = plainToClass(Report, data.report);
    const instance = plainToClass(WorkOrder, data);
    instance.report = report;
    return instance;
  }

  public async getWorkOrderDrafts(): Promise<WorkOrder[]> {
    const workOrderDraftIds = await this.workOrderDrafts.keys();
    const workOrderDrafts = [];

    for (const workOrderDraftId of workOrderDraftIds) {
      const workOrderDraft = await this.getWorkOrderDraft(workOrderDraftId);
      workOrderDrafts.push(workOrderDraft);
    }

    return workOrderDrafts;
  }

  public async deleteWorkOrderDraft(workOrder: WorkOrder): Promise<void> {
    return await this.workOrderDrafts.removeItem(workOrder.id);
  }

  public async saveReportDraft(report: Report): Promise<Report> {
    const reportData = classToPlain(report) as Report;
    await this.reportDrafts.setItem(report.id, reportData);
    return reportData;
  }

  public async getReportDraft(reportId: string): Promise<Report> {
    const data = await this.reportDrafts.getItem(reportId);
    return plainToClass(Report, data);
  }

  public async deleteReportDraft(report: Report): Promise<void> {
    return await this.reportDrafts.removeItem(report.id);
  }
}
