import { Expose, Exclude } from 'class-transformer';
import { Observable } from 'rxjs';

import { BaseFile } from './base-file';

@Exclude()
export class LocalImage extends BaseFile {
  @Expose()
  public id: string;

  @Expose()
  public name: string;

  @Expose()
  public size: string;

  @Expose()
  public thumbnailData: Observable<string>;

  @Expose()
  public data: string;

  @Expose()
  public fileType: string;

  @Expose({ toPlainOnly: true })
  public internalType = 'localImage';

  public isLocal = true;

  public deleted = null;
}
