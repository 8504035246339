import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { Navigate } from '@ngxs/router-plugin';

import { WorkOrderDraftStateModel } from './work-order-draft.state-model';
import {
  CreateWorkOrderDraftAndOpenWorkOrderForm,
  SaveWorkOrderDraft,
  LoadWorkOrderDraftById,
  OpenDraftClientInfoForm,
  OpenDraftActivitiesForm,
  OpenDraftOrderProductForm,
  OpenDraftCompletionForm,
  PatchWorkOrderDraft,
  CompleteWorkOrderDraft,
  OpenDraftClientTypeForm,
  CreateWorkOrderDraftFromExistingWorkOrder,
} from './work-order-draft.actions';
import { AuthState } from '../auth';
import { LocalStoreService } from '../../services/local-store.service';
import { ObjectGenerator } from '../../utils/object-generator';
import { ProcessUploadQueue } from '../sync';
import { WorkOrder } from '../../models';
import { Observable } from 'rxjs';
import { WorkOrdersService } from '../../services/work-orders.service';

@State<WorkOrderDraftStateModel>({
  name: 'workOrderDraft',
  defaults: {
    workOrder: null,
  },
})
@Injectable()
export class WorkOrderDraftState {
  public constructor(
    private store: Store,
    private localStore: LocalStoreService,
    private workOrderService: WorkOrdersService,
  ) {}

  @Selector()
  static workOrder(state: WorkOrderDraftStateModel): WorkOrder {
    return state.workOrder;
  }

  @Action(CreateWorkOrderDraftAndOpenWorkOrderForm)
  public async createWorkOrderDraftAndOpenWorkOrderForm(
    ctx: StateContext<WorkOrderDraftStateModel>,
    { report }: CreateWorkOrderDraftAndOpenWorkOrderForm,
  ): Promise<any> {
    const user = this.store.selectSnapshot(AuthState.user);
    const generator = new ObjectGenerator();
    const workOrder = generator.generateNewWorkOrder({ user, report });
    await this.localStore.saveWorkOrderDraft(workOrder);
    ctx.patchState({ workOrder });
    ctx.dispatch(new OpenDraftClientInfoForm());
  }

  @Action(CreateWorkOrderDraftFromExistingWorkOrder)
  public async createWorkOrderDraftFromExistingWorkOrder(
    ctx: StateContext<WorkOrderDraftStateModel>,
    { workOrder }: CreateWorkOrderDraftFromExistingWorkOrder,
  ): Promise<any> {
    const user = this.store.selectSnapshot(AuthState.user);

    if (!['admin'].includes(user.role)) {
      return;
    }

    const fetchedWorker = await this.workOrderService.getWorkOrderById(workOrder.id).toPromise();
    const generator = new ObjectGenerator();
    const clonedWorkOrder = generator.cloneExistingWorkOrder(fetchedWorker);
    await this.localStore.saveWorkOrderDraft(clonedWorkOrder);
    ctx.patchState({ workOrder: clonedWorkOrder });

    ctx.dispatch(new OpenDraftClientInfoForm());
  }

  @Action(LoadWorkOrderDraftById)
  public async loadReport(ctx: StateContext<WorkOrderDraftStateModel>, { id }: LoadWorkOrderDraftById): Promise<void> {
    const workOrder = await this.localStore.getWorkOrderDraft(id);
    ctx.patchState({ workOrder });
  }

  @Action(OpenDraftClientTypeForm)
  public openDraftClientTypeForm(_: StateContext<WorkOrderDraftStateModel>): Observable<any> {
    const user = this.store.selectSnapshot(AuthState.user);
    const workOrderDraft = this.store.selectSnapshot(WorkOrderDraftState.workOrder);
    return this.store.dispatch(new Navigate([`/${user.role}/work-orders/draft/${workOrderDraft.id}/client-type`]));
  }

  @Action(OpenDraftClientInfoForm)
  public openDraftClientInfoForm(_: StateContext<WorkOrderDraftStateModel>): Observable<any> {
    const user = this.store.selectSnapshot(AuthState.user);
    const workOrderDraft = this.store.selectSnapshot(WorkOrderDraftState.workOrder);
    return this.store.dispatch(new Navigate([`/${user.role}/work-orders/draft/${workOrderDraft.id}/client-info`]));
  }

  @Action(OpenDraftActivitiesForm)
  public openDraftActivitiesForm(ctx: StateContext<WorkOrderDraftStateModel>): Observable<any> {
    const user = this.store.selectSnapshot(AuthState.user);
    const workOrderDraft = this.store.selectSnapshot(WorkOrderDraftState.workOrder);
    return ctx.dispatch(new Navigate([`/${user.role}/work-orders/draft/${workOrderDraft.id}/activities`]));
  }

  @Action(OpenDraftOrderProductForm)
  public openDraftProductOrderForm(ctx: StateContext<WorkOrderDraftStateModel>): Observable<any> {
    const user = this.store.selectSnapshot(AuthState.user);
    const workOrderDraft = this.store.selectSnapshot(WorkOrderDraftState.workOrder);
    return ctx.dispatch(new Navigate([`/${user.role}/work-orders/draft/${workOrderDraft.id}/order-product`]));
  }

  @Action(OpenDraftCompletionForm)
  public openDraftCompletionForm(ctx: StateContext<WorkOrderDraftStateModel>): Observable<any> {
    const user = this.store.selectSnapshot(AuthState.user);
    const workOrderDraft = this.store.selectSnapshot(WorkOrderDraftState.workOrder);
    return ctx.dispatch(new Navigate([`/${user.role}/work-orders/draft/${workOrderDraft.id}/completion`]));
  }

  @Action(SaveWorkOrderDraft)
  public saveWorkOrderDraft(
    _: StateContext<WorkOrderDraftStateModel>,
    { workOrder }: SaveWorkOrderDraft,
  ): Promise<WorkOrder> {
    return this.localStore.saveWorkOrderDraft(workOrder);
  }

  @Action(PatchWorkOrderDraft)
  public patchWorkOrderDraft(
    ctx: StateContext<WorkOrderDraftStateModel>,
    { data }: PatchWorkOrderDraft,
  ): WorkOrderDraftStateModel {
    const workOrderDraft = this.store.selectSnapshot(WorkOrderDraftState.workOrder);
    const generator = new ObjectGenerator();
    const patchedWorkOrder = generator.patchWorkOrder(workOrderDraft, data);
    return ctx.patchState({ workOrder: patchedWorkOrder });
  }

  @Action(CompleteWorkOrderDraft)
  public async completeWorkOrderDraft(
    ctx: StateContext<WorkOrderDraftStateModel>,
    { workOrder }: CompleteWorkOrderDraft,
  ): Promise<void> {
    await this.localStore.saveWorkOrder(workOrder);
    await this.localStore.deleteWorkOrderDraft(workOrder);
    ctx.patchState({ workOrder: null });
    return await ctx.dispatch(new ProcessUploadQueue()).toPromise();
  }
}
