import { Expose, Exclude, Transform } from 'class-transformer';

import { defaultValueTransformer } from '../transformers';
import { ICompany } from '@vandelft/shared/interfaces';

@Exclude()
export class Company implements ICompany {
  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public id: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public name: string;

  @Expose()
  @Transform((val) => defaultValueTransformer(val, null))
  public email: string;
}
