import { Injectable, OnDestroy } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { User } from '../models';
import { ActivatedRoute } from '@angular/router';
import { AuthState } from '../state/auth';
import { OpenDepositItemsList } from '../state/deposit/deposit.actions';
import { OpenInvoicesList } from '../state/invoices/invoices.actions';
import { OpenExternalInvoicesList } from '../state/external-invoices/external-invoices.actions';
import { OpenProductsList } from '../state/products';
import { OpenReportsList } from '../state/reports';
import { OpenUsersList } from '../state/users';
import { OpenWorkOrdersList } from '../state/work-orders/work-orders.actions';
import { OpenOrdersList } from '../state/orders/orders.actions';
import { OpenStockList } from '../state/stock/stock.actions';
import { SetActiveModule, SetModules } from '../state/modules';
import { SetPrefix } from '../state/environment';
import { OpenCompaniesList } from '../state/companies';
import { OpenReportBackItemsList } from '../state/report-back-items';
import { OpenOrderedProductsList } from '@vandelft/modules/shared/state/ordered-products';

@Injectable({
  providedIn: 'root',
})
export class AppService implements OnDestroy {
  @Select(AuthState.user)
  public user$: Observable<User>;

  private destroy$ = new Subject<void>();

  public constructor(private route: ActivatedRoute, private store: Store) {
    this.user$.pipe(takeUntil(this.destroy$)).subscribe((user: User) => this.onUserChange(user));
  }

  private onUserChange(user: User): void {
    this.setModules(user);
  }

  public setModules(user: User): void {
    const role = user.role;

    const availableModules = [
      {
        icon: '/assets/img/reports.svg',
        label: 'Meldingen',
        id: 'reports',
        url: '/reports',
        action: new OpenReportsList(),
        roles: ['admin', 'mechanic', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/products.svg',
        label: 'Terugmeldingen',
        id: 'report-back',
        url: '/report-back',
        action: new OpenReportBackItemsList(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/work-orders.svg',
        label: 'Werkbonnen',
        id: 'work-orders',
        url: '/work-orders',
        action: new OpenWorkOrdersList(),
        roles: ['admin'],
      },
      {
        icon: '/assets/img/orders.svg',
        label: 'Bestellingen',
        id: 'orders',
        url: '/orders',
        action: new OpenOrdersList(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/products.svg',
        label: 'Producten',
        id: 'products',
        url: '/products',
        action: new OpenProductsList(),
        roles: ['admin'],
      },
      {
        icon: '/assets/img/financial.svg',
        label: 'Financieel',
        id: 'financial',
        action: new OpenInvoicesList(),
        url: '/financial',
        roles: ['admin', 'financial'],
      },
      {
        icon: '/assets/img/financial.svg',
        label: 'Facturatie',
        id: 'facturatie',
        action: new OpenExternalInvoicesList(),
        url: '/external-invoices',
        roles: ['admin', 'financial'],
      },
      {
        icon: '/assets/img/stock.svg',
        label: 'Voorraad',
        id: 'stock',
        url: '/stock',
        action: new OpenStockList(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/products.svg',
        label: 'Bestelde producten',
        id: 'ordered-products',
        url: '/ordered-products',
        action: new OpenOrderedProductsList(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/money.svg',
        label: 'Afletteren',
        id: 'desposit',
        url: '/deposit',
        action: new OpenDepositItemsList(),
        roles: ['admin', 'planner', 'financial'],
      },
      {
        icon: '/assets/img/customers.svg',
        label: 'Klanten',
        id: 'companies',
        url: '/companies',
        action: new OpenCompaniesList(),
        roles: ['admin'],
      },
      {
        icon: '/assets/img/users.svg',
        label: 'Gebruikers',
        id: 'users',
        url: '/users',
        action: new OpenUsersList(),
        roles: ['admin'],
      },
    ];

    const modules = [];

    for (const module of availableModules) {
      if (!module.roles.includes(role)) {
        continue;
      }

      modules.push(module);
    }

    const child = this.route.snapshot.firstChild.firstChild.firstChild;
    let activeModule = modules[0];

    if (child) {
      const path = child.routeConfig.path;
      activeModule = modules.find((m) => m.url.startsWith(`/${path}`)) || modules[0];
    }

    this.store.dispatch([new SetPrefix(role), new SetModules(modules), new SetActiveModule(activeModule)]);
  }

  public ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
