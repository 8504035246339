import { BrowserModule } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { NgModule, ErrorHandler } from '@angular/core';
import localeNl from '@angular/common/locales/nl';
import { NgxsModule } from '@ngxs/store';
import { NgxsStoragePluginModule } from '@ngxs/storage-plugin';
import { NgxsRouterPluginModule } from '@ngxs/router-plugin';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { AngularFireAuthModule } from '@angular/fire/auth';

import * as moment from 'moment-timezone';

import { SharedModule } from './modules/shared/shared.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { JwtInterceptor } from '../jwt-interceptor';

import { environment } from '../environments/environment';
import { statesList } from './modules/shared/state/states';
import { AuthGuard } from './guards/auth-guard';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { AdminGuard, FinancialGuard, MechanicGuard, PlannerGuard, SuperAdminGuard } from './guards';
import { createErrorHandler } from '@sentry/angular';

moment.tz.setDefault('utc');
registerLocaleData(localeNl, 'nl-NL');

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgxMaterialTimepickerModule,
    SharedModule,
    NgxsModule.forRoot(statesList, {
      developmentMode: !environment.production,
    }),
    NgxsReduxDevtoolsPluginModule.forRoot(),
    NgxsRouterPluginModule.forRoot(),
    NgxsStoragePluginModule.forRoot({
      key: ['auth.token'],
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    AngularFireStorageModule,
  ],
  exports: [SharedModule],
  providers: [
    AuthGuard,
    AdminGuard,
    FinancialGuard,
    MechanicGuard,
    PlannerGuard,
    SuperAdminGuard,
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        logErrors: true,
        showDialog: false,
      }),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    { provide: BUCKET, useValue: 'van-delft-werkbonnen-prod.appspot.com' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
