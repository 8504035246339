<div class="date">{{ note.created | date: 'dd-MM-yyyy' }}</div>
<div class="details">
  <div class="row">
    <div class="col col-48">
      <div>
        <b>Door</b>
        <div>{{ note.user.fullName }}</div>
      </div>
    </div>

    <div class="col col-48">
      <div>
        <b>Tijd</b>
        <div>{{ note.created | date: 'dd-MM-yyyy' }}@{{ note.created | date: 'HH:mm' }}</div>
      </div>
    </div>
  </div>

  <div class="row mt-2">
    <div class="col col-48">
      <div>
        <b>Notitie</b>
        <div>
          <div>{{ note.note }}</div>
          <a *ngIf="note.attachment" download href="{{ apiUrl }}/notes/attachment/{{ note.id }}?token={{ token }}">
            Download bijlage
          </a>
        </div>
      </div>
    </div>
  </div>
</div>
