import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';

import { ModulesState, SetActiveModule } from '@vandelft/modules/shared/state/modules';
import { DepositState, RefreshUserDepositItem } from '@vandelft/modules/shared/state/deposit';
import { AuthState } from '@vandelft/modules/shared/state/auth';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
})
export class NavigationComponent {
  @Select(ModulesState.modules)
  public modules$: Observable<any[]>;

  @Select(ModulesState.activeModule)
  public activeModule$: Observable<any>;

  @Select(DepositState.mechanicDepositItem)
  public mechanicDepositItem$: Observable<any>;

  @Select(AuthState.user)
  public user$: Observable<any>;

  public isOpen = false;

  constructor(private store: Store, public activateRoute: ActivatedRoute, public router: Router) {
    this.store.dispatch(new RefreshUserDepositItem());
  }

  public setActiveModule(module: any): void {
    this.store.dispatch(new SetActiveModule(module));
  }

  public toggle(): void {
    this.isOpen = !this.isOpen;
  }

  public open(module: any): Observable<void> {
    if (module.action) {
      return this.store.dispatch([new SetActiveModule(module), module.action]);
    }
  }
}
