import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment from 'moment/moment';
import { stringify } from 'qs';

import { Image, LocalImage } from '../models';

import { environment } from '../../../../environments/environment';
import { Order } from '../models/order';
import { plainToClass, classToPlain } from 'class-transformer';

interface OrderFilters {
  end?: string;
  start?: string;
}

@Injectable({
  providedIn: 'root',
})
export class OrdersService {
  public constructor(private http: HttpClient) {}

  public getQueryString(options?: OrderFilters): string {
    const defaultOptions = {
      end: null,
      start: null,
    };

    const mergedOptions = { ...defaultOptions, ...options };

    if (mergedOptions.start !== null && mergedOptions.start.length > 0) {
      mergedOptions.start = moment(mergedOptions.start).toDate();
    }

    if (mergedOptions.end !== null && mergedOptions.end.length > 0) {
      mergedOptions.end = moment(mergedOptions.end).toDate();
    }

    for (const key of Object.keys(mergedOptions)) {
      const value = mergedOptions[key];
      if (!value) {
        delete mergedOptions[key];
      }
    }

    return stringify(mergedOptions);
  }

  public getOrders(options?: OrderFilters): Observable<Order[]> {
    const queryString = this.getQueryString(options);
    return this.http
      .get(`${environment.apiUrl}/orders?${queryString}`)
      .pipe(map((data: Order[]) => plainToClass(Order, data)));
  }

  public getOrderById(id: string): Observable<Order> {
    return this.http.get(`${environment.apiUrl}/orders/${id}`).pipe(map((data: Order) => plainToClass(Order, data)));
  }

  public uploadCertificate(id: string, image: LocalImage): Observable<Image> {
    const reqBody = classToPlain(image) as LocalImage;
    return this.http.post<Image>(`${environment.apiUrl}/orders/${id}/certificates`, reqBody);
  }

  public uploadProductImage(id: string, image: LocalImage): Observable<Image> {
    const reqBody = classToPlain(image) as LocalImage;
    return this.http.post<Image>(`${environment.apiUrl}/orders/${id}/product-images`, reqBody);
  }
}
