import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
  ElementRef,
  forwardRef,
  OnInit,
  AfterViewInit,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl } from '@angular/forms';

@Component({
  selector: 'app-autocomplete',
  templateUrl: './autocomplete.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => AutoCompleteComponent),
      multi: true,
    },
  ],
})
export class AutoCompleteComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
  @Input()
  public items: any[];

  @ViewChild('autocomplete')
  public autocomplete: ElementRef;

  public selectedItem: any;

  public query$ = new Subject();

  public items$ = new BehaviorSubject(null);

  private onChange: any = () => {};
  public onTouch: any = () => {};

  public ngOnInit(): void {
    this.query$.pipe(debounceTime(300)).subscribe((query: string) => {
      const filteredItems = [...this.items].filter((value: any) => {
        return value.name.toLowerCase().includes(query.toLowerCase());
      });
      const nonFilteredItems = [...this.items].filter((value: any) => {
        return !value.name.toLowerCase().includes(query.toLowerCase());
      });
      this.items$.next([...filteredItems, ...nonFilteredItems]);
    });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if ('items' in changes) {
      this.items$.next(changes.items.currentValue);
    }
  }

  public ngAfterViewInit(): void {
    if (this.autocomplete) {
      this.autocomplete.nativeElement.value = this.selectedItem?.name || '';
    }
  }

  public writeValue(value: any): void {
    this.selectedItem = value;

    if (this.autocomplete) {
      this.autocomplete.nativeElement.value = this.selectedItem?.name || '';
    }
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  public onKeyUp(_: Event, query: string): void {
    this.query$.next(query);
    this.selectedItem = null;
    this.onChange(this.selectedItem);
    this.onTouch();
  }

  public setSelectedItem(_: Event, item: any): void {
    this.autocomplete.nativeElement.value = item.name;
    this.selectedItem = item;
    this.onChange(this.selectedItem);
    this.items$.next(null);
  }

  public validate(_: FormControl): any {
    if (!this.selectedItem) {
      return { invalid: true };
    }

    return null;
  }
}
