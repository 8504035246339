import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent, ForgotPasswordComponent, ActivationComponent } from './modules/shared/components/users';

import { UnauthenticatedLayoutComponent, AuthenticatedLayoutComponent } from './modules/shared/components/ui';

import * as Debug from './modules/shared/components/debug';

import { AuthGuard, AdminGuard, FinancialGuard, MechanicGuard, PlannerGuard, SuperAdminGuard } from './guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full',
  },
  {
    path: 'debug',
    component: Debug.ListComponent,
  },
  {
    path: '',
    component: UnauthenticatedLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent,
      },
      {
        path: 'users/activation/:hash',
        component: ActivationComponent,
      },
    ],
  },
  {
    path: '',
    component: AuthenticatedLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'mechanic',
        loadChildren: () => import('./modules/mechanic/mechanic.module').then((m) => m.MechanicModule),
        canActivate: [MechanicGuard],
      },
      {
        path: 'planner',
        loadChildren: () => import('./modules/planner/planner.module').then((m) => m.PlannerModule),
        canActivate: [PlannerGuard],
      },
      {
        path: 'admin',
        loadChildren: () => import('./modules/admin/admin.module').then((m) => m.AdminModule),
        canActivate: [AdminGuard],
      },
      {
        path: 'financial',
        loadChildren: () => import('./modules/financial/financial.module').then((m) => m.FinancialModule),
        canActivate: [FinancialGuard],
      },
      {
        path: 'super-admin',
        loadChildren: () => import('./modules/super-admin/super-admin.module').then((m) => m.SuperAdminModule),
        canActivate: [SuperAdminGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
