import { HttpRequest, HttpErrorResponse } from '@angular/common/http';

import { captureException } from '@sentry/angular';
import { environment } from './environments/environment';

export const captureExceptionInSentry = (
  error: HttpErrorResponse | Error,
  request?: HttpRequest<any>,
  contexts?: any,
) => {
  const effectiveError = error instanceof HttpErrorResponse ? error?.error : error;
  const message = effectiveError?.message ?? error.message;
  const captureContext = {
    contexts: {
      ...(contexts ?? {}),
      environment: JSON.parse(JSON.stringify(environment)),
      request: JSON.parse(JSON.stringify(request ?? {})),
      response: JSON.parse(JSON.stringify(error ?? {})),
    },
  };

  console.error(error, captureContext);

  captureException(new Error(message), captureContext);
};
