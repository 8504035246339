export class OpenExternalInvoicesList {
  public static readonly type = '[External Invoices] Open external invoices list';
}

export class LoadExternalInvoices {
  public static readonly type = '[External Invoices] Load external invoices';
  public constructor(public filters?: any) {}
}

export class ProcessExternalInvoices {
  public static readonly type = '[External Invoices] Process external invoices';
  public constructor(public processedExternalInvoices: any[]) {}
}
