import { State, Action, StateContext, Selector, Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { OrdersStateModel } from '@vandelft/modules/shared/state/orders/orders.state-model';
import { OrdersService } from '@vandelft/modules/shared/services/orders.service';

import { LoadOrders, LoadOrder, OpenOrdersDetails, OpenOrdersList } from './orders.actions';
import { Order } from '../../models';
import { Observable } from 'rxjs';
import { EnvironmentState } from '../environment';
import { Navigate } from '@ngxs/router-plugin';

@State<OrdersStateModel>({
  name: 'orders',
  defaults: {
    orders: [],
    order: null,
  },
})
@Injectable()
export class OrdersState {
  public constructor(private store: Store, private ordersService: OrdersService) {}

  @Selector()
  static orders(state: OrdersStateModel): Array<Order> {
    return state.orders;
  }

  @Selector()
  static order(state: OrdersStateModel): Order {
    return state.order;
  }

  @Action(LoadOrders)
  public async loadOrders(ctx: StateContext<OrdersStateModel>, { filters }: LoadOrders): Promise<OrdersStateModel> {
    const orders = await this.ordersService.getOrders(filters).toPromise();
    return ctx.patchState({ orders });
  }

  @Action(LoadOrder)
  public loadOrder(ctx: StateContext<OrdersStateModel>, { id }: LoadOrder): Observable<Order> {
    return this.ordersService.getOrderById(id).pipe(tap((order: Order) => ctx.patchState({ order })));
  }

  @Action(OpenOrdersList)
  public openOrdersList(): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);
    return this.store.dispatch(new Navigate([`/${prefix}/orders`]));
  }

  @Action(OpenOrdersDetails)
  public openOrdersDetails(_: StateContext<OrdersStateModel>, { order }: OpenOrdersDetails): Observable<any> {
    const prefix = this.store.selectSnapshot(EnvironmentState.prefix);

    if (order) {
      return this.store.dispatch(new Navigate([`/${prefix}/orders/${order.id}`]));
    }
  }
}
